html, body, #root, .app {
  @media screen and (orientation: portrait) {
    margin-bottom: 10vh;
  }
  @media screen and (orientation: landscape) {
    margin-bottom: 20vh;
  }

  font-size: 1em;
  font-family: 'Courier New', Courier, sans-serif;

  .text-github {
    font-weight: bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .container {
    a {
      color: black;
      text-decoration: underline;
    }

    a:hover {
      color: grey;
    }
  }
}
